var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "print-modal",
      "title": "Cetak Disposal Asset",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Cetak")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Awal"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_awal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_awal", $$v);
      },
      expression: "form.tgl_awal"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal Akhir"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_akhir,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_akhir", $$v);
      },
      expression: "form.tgl_akhir"
    }
  })], 1), _c('br')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }